import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Panelid1 from './Panelid1';
import Panelid2 from './Panelid2';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#cceded',
    width: '100%',
    marginTop: '12px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#00a6a6',
    
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  content: {
    flexGrow: 1,
    padding: '12px',
    paddingTop: '0px',
    backgroundColor: '#cceded',
    marginBottom: '56px',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  backgroundColor: '#cceded',
  width: '100%',
}));


export default function ContentAdrea() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Einstellungen
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
      <main className={classes.content}>

        <div>
      <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Info</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Hier können Sie die PNUM und den Namen Ihres Kindes ändern. Außerdem können Sie bei Bedarf eine zweite PNUM hinterlegen, sollten Sie mit einem weiteren Kind an den Befragungen teilnehmen.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Kind 1</Typography>
          <Typography className={classes.secondaryHeading}>
            PNUM hinterlegen
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <Panelid1 />
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Kind 2</Typography>
          <Typography className={classes.secondaryHeading}>
             Weitere PNUM hinterlegen
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <Panelid2 />
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>

      </main>
    </div>
  );
}
