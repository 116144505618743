import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Alert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import Iframe from 'react-iframe';
import './Home.css';
import Login from './Login';
import Install from './Install';
import Doubleview from './Doubleview';
import PWAPrompt from 'react-ios-pwa-prompt'
import PullToRefresh from 'react-simple-pull-to-refresh';
import IconButton from '@material-ui/core/IconButton';
import Sync from '@material-ui/icons/Sync';
import * as serviceWorker from '../serviceWorker';
// import currentdata from '../asset-manifest.json';




window.addEventListener("load", () => {
  function handleNetworkChange(event) {
    if (navigator.onLine) {
      document.body.classList.remove("offline");
    } else {
      document.body.classList.add("offline");
    }
  }
  window.addEventListener("online", handleNetworkChange);
  window.addEventListener("offline", handleNetworkChange);
});

var ua = navigator.userAgent.toLowerCase();
var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
if(isAndroid) {
  document.addEventListener("focus", function (e) {
    if (e.target instanceof HTMLInputElement) {
      // alert("Es ist ein focus-Event bei diesem Element passiert: " + e.target);
      document.body.classList.add("hidebottomnav");
    }
  }, true);
  document.addEventListener("focusout", function (e) {
    if (e.target instanceof HTMLInputElement) {
     // alert("Es ist ein focus-Event bei diesem Element passiert: " + e.target);
      document.body.classList.remove("hidebottomnav");
    }
  }, true);
}


const defaultProps = {
  state: "",
  onChange: () => {} // no need
};

const propTypes = {
  state: PropTypes.string,
  onChange: PropTypes.func
};

function doesFileExist(urlToFile) {
  try {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    xhr.send();
    if (xhr.status !== 200) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    return false;
  }
}


class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      iFrame: 'https://konsumgueterforschng.fra1.qualtrics.com/jfe/form/SV_1MpRbumn4FaIAfj?PNUM=' + window.localStorage.getItem("PanelID") + '',
      iFrame2: 'https://konsumgueterforschng.fra1.qualtrics.com/jfe/form/SV_1MpRbumn4FaIAfj?PNUM=' + window.localStorage.getItem("PanelID2") + '',
      panelid1: window.localStorage.getItem("PanelID"),
      panelid2: window.localStorage.getItem("PanelID2"),
    }
  }
  
handleRefresh() {

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const url = document.location+'/asset-manifest.json';
fetch(url)
.then((resp) => resp.json())
.then(function(data) {
  let jsondata = data.files['main.js'];
  var checkurl = document.location + jsondata;
  if(doesFileExist(checkurl) === true) {
  if(isSafari === true) {
    if ('serviceWorker' in navigator) {
      serviceWorker.unregister();
      serviceWorker.register();
    }
  }   
  setTimeout(function () { window.location.reload(""); }, 100)
  } else {
  
  }
})
.catch(function(error) {
  console.log(error);
});
/*
    let jsondata = data.files['main.js'];
    alert(jsondata);
    let words = jsondata.toString().split(',');
    var location = document.location;
    var url = location + words[0];
    alert(url);

    alert(doesFileExist(url));

    if ('serviceWorker' in navigator) {
      serviceWorker.unregister();
      serviceWorker.register();
    }
    setTimeout(function () { window.location.reload(true); }, 100);
    window.location.reload(true);
    */
	}
  

  render() {

    let panelid1 = this.state.panelid1;
    let panelid2 = this.state.panelid2;
    let displaycontent;
    if (!panelid1 && !panelid2) {
      console.log('Keine PNUM Hinterlegt');
      displaycontent = <Login />;
    } else {
      if (panelid1 && !panelid2) {
        displaycontent = <Iframe url={this.state.iFrame} width="100%" height="100%" scrolling="yes" frameborder="no" allow="autoplay" overflow="hidden"/>;
      console.log('Kind 1 vorhanden');
      }
      if (panelid1 && panelid2) {
        console.log('Zwei Kinder vorhanden');
        displaycontent = <Doubleview />;
        }
        if (!panelid1 && panelid2) {
          console.log('Kind 1 gibt es nicht, aber zwei');
          displaycontent = <Iframe url={this.state.iFrame2} width="100%" height="100%" scrolling="yes" frameborder="no" allow="autoplay" overflow="hidden"/>;

          }
    }


       



    return (
      <div className="homescreencontent">
      <CssBaseline />

      <AppBar position="fixed" className="appBar">
        <Toolbar>
        <Typography variant="h6" noWrap>
           Meine Umfragen
          </Typography>
          <div className="syncbutton">
            <IconButton
              aria-label="show more"
              aria-haspopup="true"
              onClick={this.handleRefresh}
              color="inherit"
            >
              <Sync />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <main className="content">



        {displaycontent}

      </main>
      <div className="alertmsg">
      <Alert severity="error">Kein Internet! Bitte prüfen Sie die Netzwerk-Verbindung, bevor Sie fortfahren.</Alert>
      </div>
      <Install />
      <PWAPrompt copyTitle="Zum Homescreen hinzufügen" copyBody="Diese Webseite steht als App zur Verfügung. Jetzt zum Startbildschirm hinzufügen und bequem offline und im Vollbildmodus nutzen." copyShareButtonLabel="Klick auf 'teilen'" copyAddHomeButtonLabel="Danach auf 'Zum Home-Bildschirm'" promptOnVisit={1} timesToShow={15} copyClosePrompt="Abbrechen" permanentlyHideOnDismiss={false}/>
    </div>



      
    );
  }
}

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;



export default Home