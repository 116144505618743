import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Iframe from 'react-iframe';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: '56px',
  },
  doubleiframe: {
    marginTop: '0px !important',
  },
}));



export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const panelid1 = window.localStorage.getItem("PanelID");
  const panelid2 = window.localStorage.getItem("PanelID2");
  const childName1 = window.localStorage.getItem("ChildName");
  const childName2 = window.localStorage.getItem("ChildName2");
  
  let menulabel1;
  if (childName1) {
    menulabel1 = childName1;
  } else {
    menulabel1 = panelid1;
  }
  let menulabel2;
  if (childName2) {
    menulabel2 = childName2;
  } else {
    menulabel2 = panelid2;
  }
  const panelid1url = "https://konsumgueterforschng.fra1.qualtrics.com/jfe/form/SV_1MpRbumn4FaIAfj?PNUM=" + window.localStorage.getItem("PanelID") + "";
  const panelid2url = "https://konsumgueterforschng.fra1.qualtrics.com/jfe/form/SV_1MpRbumn4FaIAfj?PNUM=" + window.localStorage.getItem("PanelID2") + "";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className="twousermenu">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={menulabel1} {...a11yProps(0)} />
          <Tab label={menulabel2} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
        <Iframe className={classes.doubleiframe} url={panelid1url} width="100%" height="100%" scrolling="yes" frameborder="no" allow="autoplay" overflow="hidden"/>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <Iframe className={classes.doubleiframe} url={panelid2url} width="100%" height="100%" scrolling="yes" frameborder="no" allow="autoplay" overflow="hidden"/>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}