import React from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

class InstallPanel extends React.Component {

  componentDidMount()
  {
    // Code to handle install prompt on desktop
    let deferredPrompt;
    const addBtn = document.querySelector('.add-button');
    const addbanner = document.querySelector('.addtohomescreen');
    addBtn.style.display = 'none';
    addbanner.style.display = 'none';
    console.log('start')

    window.addEventListener('beforeinstallprompt', (e) => {
      console.log('beforeInstall')
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
      addBtn.style.display = 'block';
      addbanner.style.display = 'block';

      addBtn.addEventListener('click', (e) => {
        console.log('clickEvent')
        // hide our user interface that shows our A2HS button
        addBtn.style.display = 'none';
        addbanner.style.display = 'none';
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }
            deferredPrompt = null;
          });
      });
    });
    let installbanner = window.localStorage.getItem("installbanner");
    if(installbanner === "hidden") {
      document.body.classList.add("hideinstaller");
    }
  }
  
  closeDialog() {
    const addbanner = document.querySelector('.addtohomescreen');
    addbanner.style.display = 'none';

    window.localStorage.setItem("installbanner","hidden");
    document.body.classList.add("hideinstaller");
}



  render() {

    return(
        <div className="addtohomescreen">
        <div>
      <List>
      <ListItem>
        <ListItemAvatar>
        <Avatar alt="App-Icon" src="./logo512.png" />
        </ListItemAvatar>
        <ListItemText primary="Umfragen" secondary="pgsurveyapp.de" />
      </ListItem>
    </List>
      <Button className="add-button" variant="contained" color="primary">
        App installieren
      </Button>
      <Button variant="outlined" onClick={this.closeDialog}>
        Schließen
      </Button>
      </div>

      </div>
    );
  }
}

export default InstallPanel;