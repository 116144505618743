import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import Alert from '@material-ui/lab/Alert';
import './Panelid1.css';

const defaultProps = {
  state: "",
  onChange: () => {} // no need
};

const propTypes = {
  state: PropTypes.string,
  onChange: PropTypes.func
};

class Panelid1 extends React.Component {
  constructor() {
    super();
    this.state = {
      panelid: window.localStorage.getItem("PanelID"),
      childname: window.localStorage.getItem("ChildName"),
    }
  }


  handleSubmit(event) {
    event.preventDefault();
    document.body.classList.add("submitalert");
    setTimeout(() => {
      document.body.classList.remove("submitalert")
    }, 3000);
      
      let newPanelid = this.state.panelid.replace(/^\s+/, '').replace(/\s+$/, '');
      if (newPanelid) {
      window.localStorage.setItem("PanelID",newPanelid);
      } else {
      window.localStorage.setItem("PanelID", "");
      }
      if (this.state.childname) {
        window.localStorage.setItem("ChildName",this.state.childname);
        } else {
          window.localStorage.setItem("ChildName", "");
        }
    console.log("state: " + this.state.panelid); //shows onChanged value in console
    console.log("state: " + this.state.childname); //shows onChanged value in console
  }
  render() {
    return (
      <div>

        <form className='settingsform' action="/" method="POST" onSubmit={this.handleSubmit.bind(this)}>
      <CardContent>
          <TextField className='settingsforminput' value={this.state.panelid} onChange={e=> {this.setState({panelid:e.target.value})}} id="outlined-basic" name="panelid" label="PNUM" inputProps={{maxLength: 10}} variant="outlined" />
          <Typography className='settingstitle' color="textSecondary" gutterBottom>
          Tragen Sie die PNUM ein, um Zugriff auf Ihren Fragebogen zu bekommen.
          </Typography>
          <TextField className='settingsforminput2' id="outlined-basic" value={this.state.childname} onChange={e=> {this.setState({childname:e.target.value})}} name="nameofchild" label="Vorname Ihres Kindes" inputProps={{maxLength: 16}} variant="outlined" />
          <Typography className='settingstitle' color="textSecondary" gutterBottom>
          Der Vorname Ihres Kindes wird nur lokal gespeichert. Diese Angabe unterstützt Sie bei der Unterscheidung der Fragebögen, sollten Sie mit mehreren Kindern an den Befragung teilnehmen.
          </Typography>
      </CardContent>
      <div className="alertmsg-submit1">
      <Alert severity="success">Eingabe wurde gespeichert.</Alert>
      </div>
      <CardActions>
      
      <Button type="reset" label="Reset" size="small" onClick={e=> {this.setState({panelid:'', childname: ''})}} color="primary" style={{ float: 'left', color: 'rgb(255, 64, 129)' }}>
          Löschen
        </Button>
        <Button type="submit"label="Submit" size="small" color="primary" style={{ float: 'right', marginRight: '0', marginLeft: 'auto' }}>
          Speichern
        </Button>
      </CardActions>
      </form>
      </div>
      
    );
  }
}

Panelid1.propTypes = propTypes;
Panelid1.defaultProps = defaultProps;



export default Panelid1