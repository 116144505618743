import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import PullToRefresh from 'react-simple-pull-to-refresh';


import Navigation from './Navigation/Navigation';

ReactDOM.render(
  <React.StrictMode>
    <Navigation/>   
  </React.StrictMode>,
  document.getElementById('root')
);



serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload();

        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
  onSuccess: () => {

  }


});



function testme() {

  const url = document.location+'/asset-manifest.json';
fetch(url)
.then((resp) => resp.json())
.then(function(data) {
  let jsondata = data.files['main.js'];
  var checkurl = document.location + jsondata;
  alert(doesFileExist(checkurl) + " url " + checkurl + " current");
  if(doesFileExist(checkurl) === true) {
    alert("App Aktuell");
     
  if ('serviceWorker' in navigator) {
    serviceWorker.unregister();
    serviceWorker.register();
  }
  setTimeout(function () { window.location.reload(""); }, 100)
  } else {
  
  }
})
.catch(function(error) {
  console.log(error);
});
}

function doesFileExist(urlToFile) {
  try {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    xhr.send();
    if (xhr.status !== 200) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    return false;
  }
}

/*
serviceWorker.register({
  onUpdate: registration => {
  alert('New version available!  Ready to update?');
  window.location.reload();
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  }
});


serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload()
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      alert('Die App wurde aktualisiert. Sie sind nun auf dem neuesten Stand.');
    }
  }
});



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
 serviceWorker.register({
  onUpdate: registration => {
    alert('Die App wurde aktualisiert. Sie sind nun auf dem neuesten Stand.');
    if (registration && registration.waiting) {

      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      setTimeout(function () { window.location.reload(true); }, 100);
    }
    //window.location.reload();
  }
});





if ('serviceWorker' in navigator) {
  window.addEventListener('load', async function() {
    const registration = await navigator.serviceWorker.register('/service-worker.js');
    if (registration.waiting && registration.active) {
      // The page has been loaded when there's already a waiting and active SW.
      // This would happen if skipWaiting() isn't being called, and there are
      // still old tabs open.
      console.log('Please close all tabs to get updates 2.');
      if ('serviceWorker' in navigator) {
        serviceWorker.unregister();


        
        setTimeout(function () { window.location.reload(""); }, 10000);
      }
    } else {
      var installingWorker = registration.installing;
      // updatefound is also fired for the very first install. ¯\_(ツ)_/¯
      registration.addEventListener('updatefound', () => {
        registration.installing.addEventListener('statechange', () => {
          if (installingWorker.state === 'installed') {
            if (registration.active) {
              // If there's already an active SW, and skipWaiting() is not
              // called in the SW, then the user needs to close all their
              // tabs before they'll get updates.
              console.log('Please close all tabs to get updates1.');
            } else {
              // Otherwise, this newly installed SW will soon become the
              // active SW. Rather than explicitly wait for that to happen,
              // just show the initial "content is cached" message.
              console.log('Content is cached for the first time!');
            }
          }
        });
      });
    }
  });
}




function unregister() {
  if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(function (registration) {
          registration.unregister();
      });
  }
}
*/