import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MenuBookTwoToneIcon from '@material-ui/icons/MenuBookTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom';
import About from '../About/About';
import Settings from '../Settings/Settings';
import Home from '../Home/Home';
import Notfound from '../Notfound'

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  }),
);

export default function ClippedDrawer() {

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  let urlpath = window.location.pathname;
  document.body.classList.remove("nav-settings");
  document.body.classList.remove("nav-about");
  document.body.classList.remove("nav-home");
  if(urlpath === "/settings") {
    document.body.classList.add("nav-settings");
  } else {
    if(urlpath === "/about") {
      document.body.classList.add("nav-about");
    } else {
      document.body.classList.add("nav-home");
    }
  }
  console.log(urlpath);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <Divider />


        <List> 
            <ListItem className="nav-home" button selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)} 
          key="Meine Umfragen" component={Link} to="/">
              <ListItemIcon>{<MenuBookTwoToneIcon />}</ListItemIcon>
              <ListItemText primary="Meine Umfragen" /> 
            </ListItem>
            <ListItem className="nav-about" button selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)} 
          key="Über die App" component={Link} to="/about">
              <ListItemIcon>{<InfoTwoToneIcon />}</ListItemIcon>
              <ListItemText primary="Über die App" />
            </ListItem>
            <ListItem className="nav-settings" button selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)} 
          key="Einstellungen" component={Link} to="/settings">
              <ListItemIcon>{<SettingsTwoToneIcon />}</ListItemIcon>
              <ListItemText primary="Einstellungen" />
            </ListItem>
        </List>
      </Drawer>
      <Switch>
          <Route path="/about" component={About} />
          <Route path="/settings" component={Settings} />
          <Route path="/" component={Home} />
          <Route component={Notfound} />
      </Switch>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction className="nav-home" label="Meine Umfragen" icon={<MenuBookTwoToneIcon />} component={Link} to="/" selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)} />
        <BottomNavigationAction className="nav-about" label="Über die App" icon={<InfoTwoToneIcon />} component={Link} to="/about" selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)} />
        <BottomNavigationAction className="nav-settings" label="Einstellungen" icon={<SettingsTwoToneIcon />} component={Link} to="/settings" selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)} />
      </BottomNavigation>

      </Router>
      
    </div>
  );
}